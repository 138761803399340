import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions

const firebaseConfig = {
    apiKey: "AIzaSyDTbbv3H7iqtMF_mjXROQgsbEEuZn9_Egk",
    authDomain: "nammonweb-test.firebaseapp.com",
    databaseURL: "https://nammonweb-test.firebaseio.com",
    projectId: "nammonweb-test",
    storageBucket: "nammonweb-test.appspot.com",
    messagingSenderId: "1005469572690",
    appId: "1:1005469572690:web:5a2fc2bfd12ea263"
  };
  firebase.initializeApp(firebaseConfig)
//const functions = require("firebase-functions");
// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
//const usersCollection = db.collection('users')
//const postsCollection = db.collection('posts')
//const commentsCollection = db.collection('comments')
//const likesCollection = db.collection('likes')
const url_name = db.collection('SHRTURL').doc('2niFtVInHljcYp20Gv34')

// export utils/refs
export {
  firebase,
  db,
  auth,
  url_name
}
