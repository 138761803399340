<template>
  <div id="app">
    <navbar/>
    <router-view/>
    <footage/>
  </div>
</template>

<style>

@font-face {
  font-family: 'PrintAble4U', sans-serif;
  src: url('./global-assets/font/PrintAble4U/regular.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PrintAble4U', sans-serif;
  src: url('./global-assets/font/PrintAble4U/bold.otf') format('otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'PrintAble4U', sans-serif;
  src: url('./global-assets/font/PrintAble4U/bold.otf') format('otf');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'PrintAble4U', sans-serif;
  src: url('./global-assets/font/PrintAble4U/italic.otf') format('otf');
  font-weight: normal;
  font-style: italic;
}
#app {
        font-family: 'PrintAble4U', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    
</style>
<script>
//Import NAV-Bar form other.vue app//
import navbar from "./global-assets/vue/nav-bar.vue";
import footage from "./global-assets/vue/footage.vue"
//Export NAV-Bar form This Function//
export default {
  components: {navbar,footage}
}
    
</script>