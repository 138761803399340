<template>
<div id="navebar">
    <b-navbar type="dark" variant="dark">
    <b-navbar-brand type="light" to="/" tag="h1" class="mb-0">
      <img src="../picture/nav3030.png" alt="n4mmonlogo" class="d-inline-block align-top" >
      n4mmon | nammon.men
    </b-navbar-brand>
    <b-collapse id="nav-text-collapse" is-nav>
      <b-navbar-nav >
      <b-nav-item to="/facebook" >Facebook</b-nav-item>
      <b-nav-item to="/instagram" >Instagram</b-nav-item>
      <b-nav-item to="/youtube" >Youtube</b-nav-item>
      <b-nav-item to="/discord" >Discord</b-nav-item>
      <b-nav-item to="/about" >About</b-nav-item>
    </b-navbar-nav>
    </b-collapse>
    <b-navbar-nav >
      
      <b-avatar variant="primary" src="" badge badge-top badge-offset="-0.5em"></b-avatar>
      <b-nav-item to="/account" >Account</b-nav-item>
    <b-nav-item>Alert</b-nav-item>
    </b-navbar-nav >
  </b-navbar>
  
</div>
</template>

<script>

export default {
  name: "navbar"
}
</script>

<style scoped>
*{
text-align: center;

}
</style>