import 'mutationobserver-shim'
//vue router
import router from './router'
//---------------------------------------------------------------------------------
import {firebase,auth} from './global-assets/js/firebase.service'
//Default vue Setting
import Vue from 'vue'
import { BNavbar } from 'bootstrap-vue'
Vue.component('b-navbar', BNavbar)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//vue bootstrap
import './global-assets/plugins/bootstrap-vue.js'
import {BootstrapVue} from 'bootstrap-vue'
import App from './App.vue'
Vue.config.productionTip = false
Vue.use(BootstrapVue)

/*
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
*/
//---------------------------------------------------------------------------------

//---------------------------------------------------------------------------------
//firebase setup
// Firebase App (the core Firebase SDK) is always required and must be listed first
// import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// Add the Firebase products that you want to use

Vue.use(firebase) 

//---------------------------------------------------------------------------------
let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})