import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '../global-assets/js/firebase.service'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/page/webpage/home.vue')
  },
  {
    path:'*',
    name:'everything notfound',
    redirect: '/404'
  },
  {
    path:'/404',
    name:'404_notfound',
    component: () => import('../global-assets/vue/404')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/page/webpage/about.vue')
  },
  {
    path: '/generate',
    name: 'Generate',
    component: () => import('../components/page/shorturl/panel.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/g',
    name: 'Redirect',
    component: () => import('../components/page/shorturl/assets/redirect')
  },
  {
    path: '/play',
    name: 'playground',
    component: () => import('../components/page/webpage/playground')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/page/panel/account/login')
  },
  {
    path: '/page',
    name: 'page',
    component: () => import('../components/page/webpage/page')
  },
  {
    path: '/todo ',
    name: 'todo',
    component: () => import('../components/page/webpage/todo')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../components/page/panel/account/logout'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../components/page/panel/generalsetting'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/facebook',
    name: 'facebokpage',
    component() {location.href = 'https://fb.com/n4mmonofficial'}
  },
  {
    path: '/instagram',
    name: 'igpage',
    component() {location.href = 'https://instagram.com/n4mmonofficial'}
  },
  {
    path: '/youtube',
    name: 'youtube_channel',
    component() {location.href = 'https://youtube.com/channel/UC6QQeCb1pn63PPZXtgwsbWw'}
  },
  {
    path: '/discord',
    name: 'discord',
    component() {location.href = 'https://discord.gg/Fy6pFkH'}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
