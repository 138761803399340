<template>
  <div id="footage">
    COPYRIGHT 2019-2021 ALL RIGHTED RESERVED AND DEV by N4MMON | NAMMON.MEN
  </div>
</template>

<script>
export default {
name: "footage"
}
</script>

<style scoped>
div{
  text-align: center;
}
</style>